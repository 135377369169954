import { Flex, Text } from '@smart/react-components';
import { isValidDate } from '@ui/library/helpers/date-locale';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import { marketCodeTimeZoneMap } from '@utils/market/types';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { useTranslation } from 'react-i18next';
import OrderItemFeature from '../OrderItems/OrderItemFeature';
import { AldSection } from './CartItems.config';
import './CartItems.scss';

const BASE_CLASS = 'adb-cart-items';
const identityStatuses = ['started', 'completed', 'complete'];

const BaseInf = ({ detail }: AldSection) => {
  const { locale } = useLanguageContext();
  const { market } = useMarketContext();
  const { t } = useTranslation();

  const useAldIdentityStatus = useFeatureFlag({
    key: 'use-aldIdentityStatus',
    defaultValue: false,
  });

  const getDaysDifference = (date: string) => {
    if (!isValidDate(date)) {
      return '-';
    }

    const differenceInMilliseconds = Math.abs(
      new Date().getTime() - new Date(date).getTime()
    );
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    if (differenceInDays > 1) {
      return `${Math.floor(differenceInDays)} days ago`;
    }
    return new Date(date).toLocaleString(locale, {
      timeZone: marketCodeTimeZoneMap[market],
      dateStyle: 'medium',
      timeStyle: 'short',
    });
  };

  return (
    <Flex direction="column" width="full" gap={300}>
      <Text variant="lbl-200">{detail?.name}</Text>
      <Flex
        alignContent="space-between"
        width="full"
        wrap="wrap"
        gap={300}
        className={`${BASE_CLASS}__features`}
      >
        <OrderItemFeature
          title={t('general.labels.customer')}
          text={detail?.customer}
        />
        {useAldIdentityStatus &&
        identityStatuses.includes(detail?.identityStatus.toLowerCase()) ? (
          <OrderItemFeature
            title={t('orders.ald_carts.leasing_status')}
            text={t(
              `orders.ald_carts.identity_status.${detail?.identityStatus.toLowerCase()}`
            )}
          />
        ) : (
          <OrderItemFeature
            title={t('orders.ald_carts.leasing_status')}
            text={
              detail?.aldStatus !== '-'
                ? t(
                    `orders.ald_carts.ald_status.${detail?.aldStatus.toLowerCase()}`
                  )
                : '-'
            }
          />
        )}
        <OrderItemFeature
          title={t('orders.ald_carts.last_updated')}
          text={getDaysDifference(detail?.inStatusForDays)}
        />
      </Flex>
    </Flex>
  );
};

export default BaseInf;
