import { Document } from '@smart/adb-shared';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { Text } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import { DocumentSource } from '../documents-context';
import { EmbeddedSigning, Signer } from './DMP/EmbeddedSigning';
import ELOSignDocument from './ELO/ELOSignDocument';
import './SignDocument.scss';

const BASE_CLASS = 'adb-sign-document';
const id = 'sign-document-modal';

const SignDocument = ({
  docSystem,
  document,
  signers,
  onClose,
  onDMPSignCompleted,
}: {
  document: Document;
  signers: Signer[];
  docSystem: DocumentSource;
  onClose: () => void;
  onDMPSignCompleted: () => void;
}) => {
  const { t } = useTranslation();
  const isELO = docSystem === DocumentSource.ELO;

  return (
    <div className={BASE_CLASS}>
      <AdbDialog size="maximized" id={id} onClose={isELO ? onClose : undefined}>
        <AdbDialog.Header>
          <Text variant="hl-100">
            {t('form_fields.documents.signature_title')}
          </Text>
        </AdbDialog.Header>
        <AdbDialog.Content>
          {isELO ? (
            <ELOSignDocument documentId={document.id} />
          ) : (
            <EmbeddedSigning
              document={document}
              signers={signers}
              onSignCompleted={onDMPSignCompleted}
            />
          )}
        </AdbDialog.Content>
      </AdbDialog>
    </div>
  );
};

export default SignDocument;
