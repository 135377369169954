import { CreateEmbeddedSigningUrlResponse, Document } from '@smart/adb-shared';
import AdbIcon from '@smart/components-adb/atoms/AdbIcon/AdbIcon';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button, Flex, Text } from '@smart/react-components';
import { useLanguageContext } from 'contexts/language-context';
import { useNotificationContext } from 'contexts/notification-context';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './EmbeddedSigning.scss';
import {
  useInitiateDocuSignQuery,
  useLoadDocuSignUrlLazyQuery,
} from './queries.generated';
import useScript from './useScript';

declare global {
  interface Window {
    DocuSign?: any;
  }
}
type DocuSignSessionEndType =
  | 'signing_complete'
  | 'cancel'
  | 'decline'
  | 'exception'
  | 'fax_pending'
  | 'session_timeout'
  | 'ttl_expired'
  | 'viewing_complete';

export type Signer = {
  signerName: string;
  signerEmail: string;
};

const signingElementId = 'signing-ceremony';

const BASE_CLASS = 'embedded-signing';

export const EmbeddedSigning = ({
  document,
  signers,
  onSignCompleted,
}: {
  document: Document;
  signers: Signer[];
  onSignCompleted?: () => void;
}) => {
  const { t } = useTranslation();
  const { addError } = useNotificationContext();
  const { language } = useLanguageContext();
  const { closeModal } = useModal();

  const signingRef = useRef<HTMLDivElement>(null);
  const [signersLeft, setSignersLeft] = useState<Signer[]>([]);
  const [signCompleted, setSignCompleted] = useState<boolean>(false);
  const [loadingUrl, setLoadingUrl] = useState<boolean>(true);
  const { id: documentId, name: documentName } = document;

  const script = useScript(import.meta.env.DMP_DOCUSIGN_JS_LIB, {
    removeOnUnmount: true,
  });

  const { loading: initiateLoading, data } = useInitiateDocuSignQuery({
    variables: {
      input: {
        documentId,
        signers,
      },
    },
    fetchPolicy: 'network-only',
    skip: script !== 'ready' || !documentId || !signers,
    onError: () => {
      addError({
        label: t('customer.documents.notification.error_title'),
        message: t('customer.documents.notification.error_document_sign_url'),
      });
      closeModal();
    },
  });

  const [fetchDocuSignUrl] = useLoadDocuSignUrlLazyQuery();
  const isLoading = script === 'loading' || initiateLoading || loadingUrl;

  useEffect(() => {
    if (data?.initiateDocuSign.pendingSigners) {
      setSignersLeft([...(data?.initiateDocuSign.pendingSigners ?? [])]);
    }
  }, [data]);

  useEffect(() => {
    const handleEvents = (event: MessageEvent) => {
      if (
        event.data &&
        event.data.sender === '@embedded/signing-ui' &&
        event.data.eventType === 'sessionEnd'
      ) {
        switch (event.data.content.sessionEndType as DocuSignSessionEndType) {
          case 'signing_complete':
            setSignersLeft((prev) => {
              const newPrev = [...prev];
              newPrev.shift();

              if (!newPrev.length) {
                setSignCompleted(true);
                onSignCompleted?.();
              }

              return newPrev;
            });
            break;
          case 'cancel':
          case 'decline':
          case 'exception':
          case 'fax_pending':
          case 'session_timeout':
          case 'ttl_expired':
          case 'viewing_complete':
          default:
            closeModal();
            break;
        }

        window.removeEventListener('message', handleEvents);
      }
    };

    window.addEventListener('message', handleEvents);
    return () => window.removeEventListener('message', handleEvents);
  });

  const loadDocuSignView = useCallback(
    async (docuSignUrl: CreateEmbeddedSigningUrlResponse) => {
      if (
        window.DocuSign &&
        data &&
        data.initiateDocuSign.signatureId &&
        docuSignUrl.clientId &&
        docuSignUrl.url
      ) {
        const signingConfiguration = {
          url: `${docuSignUrl.url}${language ? `&locale=${language}` : ''}`,
          displayFormat: `focused`,
        };

        try {
          const docusign = await window.DocuSign.loadDocuSign(
            docuSignUrl.clientId
          );

          const signing = docusign.signing(signingConfiguration);

          signing.mount(`#${signingElementId}`);
        } catch (e: any) {
          addError({
            label: t('customer.documents.notification.error_title'),
            message: t(
              'customer.documents.notification.error_document_sign_url'
            ),
          });
          closeModal();
        }
      }
    },
    [addError, closeModal, data, language, t]
  );

  const loadDocusignUrl = useCallback(async () => {
    if (signingRef?.current) {
      signingRef.current.innerHTML = '';
    }
    const signer = [...signersLeft].shift();
    if (!data || !signer) return;

    setLoadingUrl(true);

    const { signatureId } = data.initiateDocuSign;

    const docuSignUrl = await fetchDocuSignUrl({
      variables: {
        input: {
          documentId,
          signatureId,
          signer: {
            signerEmail: signer.signerEmail,
            signerName: signer.signerName,
          },
        },
      },
      fetchPolicy: 'network-only',
      onError: () => {
        addError({
          label: t('customer.documents.notification.error_title'),
          message: t('customer.documents.notification.error_document_sign_url'),
        });
        closeModal();
      },
    });
    if (docuSignUrl.data?.loadDocuSignUrl) {
      loadDocuSignView(docuSignUrl.data?.loadDocuSignUrl);
    }
    setLoadingUrl(false);
  }, [
    addError,
    closeModal,
    data,
    documentId,
    fetchDocuSignUrl,
    loadDocuSignView,
    signersLeft,
    t,
  ]);

  useEffect(() => {
    if (script === 'ready' && data) {
      loadDocusignUrl();
    }
  }, [data, loadDocusignUrl, script]);

  return (
    <>
      {!signCompleted && (
        <Flex
          direction="column"
          alignItems="center"
          className={`${BASE_CLASS}`}
        >
          {!initiateLoading && !!signersLeft.length && !!signers.length && (
            <>
              <Text variant="hl-200">
                {`${t('customer.documents.table.actions.sign')} `}
                {signers.findIndex(
                  (signer) => signer.signerEmail === signersLeft[0].signerEmail
                ) + 1}
                /{signers.length}
              </Text>
              <Text variant="cap-100" className={`${BASE_CLASS}__label`}>
                {signersLeft[0].signerName} ({signersLeft[0].signerEmail})
              </Text>
            </>
          )}
          <div
            ref={signingRef}
            style={{ ...(isLoading && { height: 0 }) }}
            id={signingElementId}
          />
          {isLoading && <LoadingIndicator />}
        </Flex>
      )}
      {signCompleted && (
        <Flex direction="column" alignItems="center">
          <AdbIcon
            icon="successful-checkmark"
            size="large"
            additionalClasses={`${BASE_CLASS}__label`}
          />
          <Text>{`${t('customer.documents.headers.name')}: ${documentName}`}</Text>
          <Text className={`${BASE_CLASS}__label`}>
            {`${t('general.labels.status')}: ${t('customer.documents.table.status.signed')}`}
          </Text>
          <Button onClick={() => closeModal()}>
            {t('feature_calendar.general.buttons.close')}
          </Button>
        </Flex>
      )}
    </>
  );
};
